<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <v-app-bar flat app clipped-left dense color="blueColorVariant1">
      <div class="fontStyleVariant4 white--text">bank<span class="fontStyleVariant5">Xchange</span> Privacy Policy</div>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <v-card-text>
        <v-card flat>
          <div class="fontStyleVariant3">Introduction</div>

          <br />
          <div>Welcome to the <b>bankXchange</b> app, developed by <b>MBCA</b>. We are committed to protecting your personal information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app.</div>
          <br />

          <div class="fontStyleVariant3">Information We Collect</div>
          <br />
          <div class="font-weight-bold">1. Personal Information</div>

          <ul>
            Account Information: When you create an account, we may collect information such as your name, email address, phone number, job title, and the financial institution you are associated with.
          </ul>
          <ul>
            Authentication Data:This includes passwords and security questions to secure your account.
          </ul>

          <div class="font-weight-bold">2. Usage Data</div>

          <ul>
            Log Information: We automatically collect log data, including your IP address, app usage details, access times, and browser type.
          </ul>

          <ul>
            Device Information:Information about your device, such as device type, operating system, and unique device identifiers.
          </ul>

          <div class="font-weight-bold">3. Communication Data</div>
          <ul>
            Support and Feedback:Information you provide when you contact support or give feedback.
          </ul>
          <ul>
            Device Information:Information about your device, such as device type, operating system, and unique device identifiers.
          </ul>
          <br />
          <div class="fontStyleVariant3">How We Use Your Information</div>
          <br />
          <div>We use the information we collect in the following ways:</div>

          <ul>
            To Provide and Maintain Our Service: Ensuring you can access and use the app effectively.
          </ul>

          <ul>
            To Improve Our Service: Enhancing user experience by understanding how you use the app.
          </ul>

          <ul>
            To Communicate with You: Sending updates, security alerts, and support messages.
          </ul>

          <ul>
            To Ensure Security: Protecting our app and users from unauthorized access and fraudulent activities.
          </ul>
          <br />
          <div class="fontStyleVariant3">Sharing Your Information</div>
          <br />
          We do not share your personal information with third parties, except in the following circumstances:

          <ul>
            With Your Consent: When you have given explicit permission.
          </ul>
          <ul>
            For Legal Reasons: When required by law, or to respond to legal processes.
          </ul>

          <ul>
            With Service Providers: Who perform services on our behalf, such as hosting and data analysis, and are bound by confidentiality agreements.
          </ul>
          <br />
          <div class="fontStyleVariant3">Data Security</div>
          <br />

          <div>We use administrative, technical, and physical security measures to protect your personal information. However, no electronic transmission or storage method is 100% secure, and we cannot guarantee its absolute security.</div>
          <br />
          <div class="fontStyleVariant3">Your Privacy Rights</div>
          <br />
          Depending on your location, you may have the right to:

          <ul>
            the personal information we hold about you.
          </ul>
          <ul>
            Request corrections to incorrect or incomplete information.
          </ul>
          <ul>
            Request the deletion of your personal information.
          </ul>

          <ul>
            Object to our processing of your personal information.
          </ul>
          <br />
          <div class="fontStyleVariant3">Changes to This Privacy Policy</div>
          <br />
          <div>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy in the app. You are advised to review this Privacy Policy periodically for any changes. Contact Us If you have any questions or concerns about this Privacy Policy,
          </div>

          <div>please contact us at: Email: rehaan@mobil80.com Address:Mobil80 Solutions & Services Pvt. Ltd., No.588,Vidyaranyapura main road, HMT Layout,Bangalore-560097</div>
        </v-card>
      </v-card-text>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<style></style>
